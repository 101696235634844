// Customizable Area Start
import React from "react";
import { useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";

type ViewerProps = {
  document: string;
};

const PDFTronWrapper = ({ document }: ViewerProps) => {
  const viewer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    WebViewer(
      {
        path: "lib",
        initialDoc: document,
      },
      viewer.current as HTMLDivElement
    ).then((instance) => {
      // you can now call WebViewer APIs here...
      instance.UI.enableElements(["contentEditButton"]);
    });
  }, []);

  return (
    <div className="PDFTronWrapper" style={{ width: "100vw", height: "95vh" }}>
      <div ref={viewer} />
    </div>
  );
};

export default PDFTronWrapper;
// Customizable Area End
